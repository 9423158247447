import _ from 'lodash'
import { getAttachment } from './field-dto/field-dto'
import { getFieldType, FIELD_TYPE } from './viewer-utils'
import {
  FormError,
  ErrorName,
  FetchError,
  NetworkError,
  RegistrationError,
  UploadFileError,
  UploadSignatureError,
  FieldValidity,
} from './errors'

const ON_FORM_SUBMIT = 'formSubmit'
const ON_FORM_SUBMIT_ERROR = 'formSubmitError'

const SUPPORTED_CONTROLLERS: ControllerType[] = [
  'getSubscribers',
  'multiStepForm',
  'wixForms',
  'registrationForm',
]

export const CORVID_ERROR_CODE = {
  INVALID_FIELD: 'INVALID_FIELD',
  FILE_NOT_UPLOADED: 'FILE_NOT_UPLOADED',
  INVALID_ARGUMENT: 'INVALID_ARGUMENT',
  UNAUTHENTICATED: 'UNAUTHENTICATED',
  PERMISSION_DENIED: 'PERMISSION_DENIED',
  RESOURCE_EXHAUSTED: 'RESOURCE_EXHAUSTED',
  UNAVAILABLE: 'UNAVAILABLE',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
}

const getFieldValue = (field, attachments) => {
  const fieldType = getFieldType(field)

  switch (fieldType) {
    case FIELD_TYPE.CHECKBOX:
      return field.checked
    case FIELD_TYPE.CAPTCHA:
      return field.token
    case FIELD_TYPE.FILE_UPLOAD: // we need this for multi-step form
      const attachment = getAttachment(field, attachments)
      return attachment
        ? [
            {
              url: attachment.url,
              mediaId: attachment.mediaId,
              title: attachment.title,
              width: attachment.width,
              height: attachment.height,
            },
          ]
        : []
    case FIELD_TYPE.SIGNATURE: // we need this for multi-step form
      return _.get(getAttachment(field, attachments), 'value', '')
    default:
      return field.value
  }
}

const errorNameToHandler: {
  [key: string]: (error: FormError) => { code: string; message: string }
} = {
  [ErrorName.FetchError]: (error: FetchError) => {
    switch (error.status) {
      case 403:
        return { code: CORVID_ERROR_CODE.PERMISSION_DENIED, message: error.data }
      case 400:
        return { code: CORVID_ERROR_CODE.INVALID_ARGUMENT, message: error.data }
      case 401:
        return { code: CORVID_ERROR_CODE.UNAUTHENTICATED, message: error.data }
      case 429:
        return { code: CORVID_ERROR_CODE.RESOURCE_EXHAUSTED, message: error.data }
      default:
        return { code: CORVID_ERROR_CODE.UNKNOWN_ERROR, message: error.data }
    }
  },
  [ErrorName.NetworkError]: (error: NetworkError) => ({
    code: CORVID_ERROR_CODE.UNAVAILABLE,
    message: error.message,
  }),
  [ErrorName.RegistrationError]: (_error: RegistrationError) => ({
    // let's not support registration form for now
    code: CORVID_ERROR_CODE.UNKNOWN_ERROR,
    message: 'unknown error',
  }),
  [ErrorName.UploadFileError]: (error: UploadFileError) => ({
    code: CORVID_ERROR_CODE.FILE_NOT_UPLOADED,
    message: error.message,
  }),
  [ErrorName.UploadSignatureError]: (error: UploadSignatureError) => ({
    code: CORVID_ERROR_CODE.FILE_NOT_UPLOADED,
    message: error.message,
  }),
  [ErrorName.FieldValidity]: (error: FieldValidity) => ({
    code: CORVID_ERROR_CODE.INVALID_FIELD,
    message: error.message,
  }),
}

export class CorvidAPI {
  private $w
  private controllerType: ControllerType

  constructor($w, controllerType: ControllerType) {
    this.$w = $w
    this.controllerType = controllerType
  }

  public fireFormSubmit({ fields, attachments }) {
    try {
      this.$w.fireEvent(
        ON_FORM_SUBMIT,
        this.$w.createEvent('wixFormSubmittedEvent', {
          fields: fields.map(field => ({
            id: field.id,
            fieldValue: getFieldValue(field, attachments),
            fieldName: field.connectionConfig.crmLabel,
          })),
        }),
      )
    } catch (_e) {}
  }

  public fireSubmitError({ error }: { error: FormError }) {
    try {
      this.$w.fireEvent(
        ON_FORM_SUBMIT_ERROR,
        this.$w.createEvent(
          'wixFormSubmittedErrorEvent',
          errorNameToHandler[_.get(error, 'name')]
            ? errorNameToHandler[error.name](error)
            : { code: CORVID_ERROR_CODE.UNKNOWN_ERROR, message: 'unknown error' },
        ),
      )
    } catch (_e) {}
  }

  private _onWixFormSubmitted(cb) {
    if (_.isFunction(cb)) {
      this.$w.on(ON_FORM_SUBMIT, cb)
    }
  }

  private _onWixFormSubmittedError(cb) {
    if (_.isFunction(cb)) {
      this.$w.on(ON_FORM_SUBMIT_ERROR, cb)
    }
  }

  public createController(initialController) {
    return _.includes(SUPPORTED_CONTROLLERS, this.controllerType)
      ? {
          ...initialController,
          exports: () => ({
            onWixFormSubmitted: cb => this._onWixFormSubmitted(cb),
            onWixFormSubmittedError: cb => this._onWixFormSubmittedError(cb),
          }),
        }
      : initialController
  }
}
