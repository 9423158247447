import _ from 'lodash'
import { FIELDS_ROLES } from '../../constants/roles'
import { submitUtils } from '../submit-utils'
import { CRM_TYPES } from '../../constants/crm-types-tags'
import { EMAIL_REGEX, URL_REGEX } from '../services/constants'
import { isUploadButton } from '../viewer-utils'

const getFileExtension = file => file.split('.').pop()

const FILES_TYPE = {
  IMAGE: 'Image',
  DOCUMENT: 'Document',
  VIDEO: 'Video',
  AUDIO: 'Audio',
}

const ACCEPTABLE_FILE_TYPES = {
  [FILES_TYPE.IMAGE]: '.jpeg,.png,.jpg,.bmp,.gif,.heic,.heif,.jfif,.jpe,.tif,.tiff,.webp',
  [FILES_TYPE.DOCUMENT]:
    '.pdf,.doc,.docx,.ppt,.pptx,.xls,.xlsx,.odp,.odt,.epub,.ai,.cdr,.csv,.dot,.dotx,.dwg,.eps,.fla,.mobi,.numbers,.odg,.ods,.pages,.pot,.potx,.pps,.ppsx,.psd,.pub,.rtf,.txt,.vcf,.xcf,.xlsm,.xlt,.xltx,.ott,.ots,.otp,.key,.indd,.gpx,.kml,.kmz,.xps,.oxps,.ipynb,.pkg',
  [FILES_TYPE.VIDEO]:
    '.avi,.mpeg,.mpg,.mpe,.mp4,.mkv,.webm,.mov,.ogv,.vob,.m4v,.3gp,.divx ,.xvid,.mxf,.wmv,.flv,.m2ts',
  [FILES_TYPE.AUDIO]: '.wav,.mp3,.flac,.m4a,.wma,.aac,.aif,.aiff',
}

//https://github.com/wix-private/crm/blob/master/user-activity-domain/src/main/scala/com/wixpress/useractivity/entities/ContactUpdate.scala
export const CRM_MAX_LENGTH = {
  [CRM_TYPES.FIRST_NAME]: 100,
  [CRM_TYPES.ADDRESS]: 250,
  [CRM_TYPES.EMAIL]: 250,
  [CRM_TYPES.COMPANY]: 100,
  [CRM_TYPES.POSITION]: 100,
  [CRM_TYPES.LAST_NAME]: 100,
  [CRM_TYPES.PHONE]: 50,
}

const CRM_PATTERNS = {
  [CRM_TYPES.EMAIL]: EMAIL_REGEX,
  [CRM_TYPES.WEBSITE]: URL_REGEX,
}

export const addContactsValidation = ({ $w }) => {
  const fields = submitUtils.getFields({ $w, roles: FIELDS_ROLES })
  const maxLengthValidation = (field, crmType) => {
    const crmMaxLength = _.get(CRM_MAX_LENGTH, crmType)
    if (crmMaxLength) {
      const fieldMaxLength = _.isNumber(field.maxLength) ? field.maxLength : Infinity
      field.maxLength = Math.min(fieldMaxLength, crmMaxLength)
    }
  }

  const patternValidation = (field, crmType) => {
    if (CRM_PATTERNS[crmType]) {
      field.pattern = CRM_PATTERNS[crmType]
    }
  }

  fields.forEach(field => {
    const crmType = _.get(field, 'connectionConfig.crmType')
    maxLengthValidation(field, crmType)
    patternValidation(field, crmType)

    if (!isUploadButton(field)) {
      return
    }

    field.onChange(() => {
      if (
        _.isEmpty(field.value) ||
        _.includes(
          ACCEPTABLE_FILE_TYPES[field.fileType].split(','),
          `.${_.toLower(getFileExtension(field.value[0].name))}`,
        )
      ) {
        return
      }
      field.reset()
    })
  })
}
