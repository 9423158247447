const CDN = 'https://settings.parastorage.com'

export const AppSettingsViewer = ({ appDefId, scope, externalId, instanceId }) => ({
  getAll: async () => {
    if (!externalId) {
      return {}
    }

    const url = `${CDN}/v1/settings/${appDefId}/${instanceId}/${scope}/${externalId}`

    const response = await fetch(url, {
      method: 'GET',
    })

    return await response.json()
  },
})
